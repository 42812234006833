export const translation = {
  projectName: '1stClassJets - Aircraft Management',

  header: {
    navigation: {
      default: [
        {
          name: 'Anmelden',
          link: '/',
        },
      ],
      ADMIN: [
        {
          name: 'Home',
          link: '/admin/customer',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  home: {
    text: 'This page is landing soon',
  },

  footer: {
    contact: 'Kontakt',
    links: 'Links',
    followUs: 'Folge uns auf',
    legalLinks: [
      { name: 'Impressum', link: '/impressum' },
      { name: 'Datenschutz', link: '/datenschutz' },
    ],
    socialLinks: [
      { icon: 'linkedin', link: 'https://www.linkedin.com/company/1stclassjets' },
      { icon: 'instagram', link: 'https://www.instagram.com/1stclassjets/' },
      { icon: 'facebook', link: 'https://www.facebook.com/1stClassJets' },
    ],
  },

  generic: {
    level: {
      title: 'Rolle',
      ADMIN: 'Administrator',
      MEMBER: 'Mitarbeiter',
    },
    company: {
      brandName: '1stClassJets',
      name: 'IKR Aviation Services GmbH',
      street: 'Kurfürstendamm 14',
      city: '10719 Berlin',
      phone: '+49 (0) 30 992 113 394',
      mail: 'mail@1stclassjets.de',
    },
    settings: 'Einstellungen',
    hourSuffix: ' Stunden',
    squareMeterSuffix: ' m²',
    timestampPrefix: 'Erstellt am: ',
    phonePrefix: 'Telefon: ',
    mailPrefix: 'E-Mail: ',
    websitePrefix: 'Webseite: ',
    employeeSuffix: ' Mitarbeiter',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    personType: 'Art',
    personTypePlaceholder: 'Beirat, Geschäftsführer, ...',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    identifier: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    kilometerSuffix: ' km',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
    export: 'Export',
    import: 'Import',
    save: 'Speichern',
  },

  UI: {
    csvImport: {
      title: 'Import',
      startCTA: 'Import starten',
      loading: 'Daten werden imporiert...',
      error: 'Der Import ist fehlgeschlagen.',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
      search: 'Suche',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Maximale Dateigröße überschritten',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
