import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import bg from '../../assets/image/home/bg.jpg'
import logo from '../../assets/image/logo/white.svg'
import { Background, BackgroundBlur } from './Home.Styled'
import props from '../../redux/props'

const Home = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Layout hideNavigation>
      <Background src={bg}>
        <BackgroundBlur className="bg-gray bg-opacity-40 flex h-full items-center justify-center">
          <div className="flex flex-col items-center gap-20 mb-20">
            <img src={logo} alt="1stClassJets" className="w-[60%] md:w-64 h-auth" />
            <div className="text-white text-center text-3xl md:text-4xl uppercase font-bold">{t.home.text}</div>
          </div>
        </BackgroundBlur>
      </Background>
    </Layout>
  )
}

export default Home
